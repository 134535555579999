var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Dependencies } from '~/core/decorator';
import { CaseApplyManageService } from '~/services/business-service/case-apply-manage.service';
import { Emit, Prop } from 'vue-property-decorator';
import { SortService } from "~/utils/sort.service";
import { PageService } from '~/utils/page.service';
import { UserService } from '~/services/manage-service/user.service';
import DataBox from '~/components/common/data-box.vue';
var LeaveCaseManageResolve = /** @class */ (function (_super) {
    __extends(LeaveCaseManageResolve, _super);
    function LeaveCaseManageResolve() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.dataSet = [];
        _this.SearchModel = {
            flowType: 'LEAVE_CASE_APPLY',
            approvalStatus: 'WAIT_APPROVAL',
            exportState: 'EXPORTED',
            applyDateStart: '',
            applyDateEnd: ''
        };
        return _this;
    }
    LeaveCaseManageResolve.prototype.refreshList = function () { };
    LeaveCaseManageResolve.prototype.close = function () { };
    LeaveCaseManageResolve.prototype.approvedMeth = function (rowData) { };
    LeaveCaseManageResolve.prototype.refreshData = function () {
        var _this = this;
        this.SearchModel = Object.assign(this.SearchModel, this.leaveModel);
        this.SearchModel.applyDateStart = this.leaveModel.applyDate[0];
        this.SearchModel.applyDateEnd = this.leaveModel.applyDate[1];
        this.caseApplyManageService.getProcessApproveMessage(this.SearchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    //审批操作
    LeaveCaseManageResolve.prototype.approvedSupplementMeth = function (scope) {
        var rowData = scope.row;
        this.approvedMeth(rowData);
    };
    __decorate([
        Dependencies(SortService)
    ], LeaveCaseManageResolve.prototype, "sortService", void 0);
    __decorate([
        Dependencies(PageService)
    ], LeaveCaseManageResolve.prototype, "pageService", void 0);
    __decorate([
        Dependencies(UserService)
    ], LeaveCaseManageResolve.prototype, "userService", void 0);
    __decorate([
        Dependencies(CaseApplyManageService)
    ], LeaveCaseManageResolve.prototype, "caseApplyManageService", void 0);
    __decorate([
        Emit('refreshList')
    ], LeaveCaseManageResolve.prototype, "refreshList", null);
    __decorate([
        Emit('close')
    ], LeaveCaseManageResolve.prototype, "close", null);
    __decorate([
        Emit('approvedMeth')
    ], LeaveCaseManageResolve.prototype, "approvedMeth", null);
    __decorate([
        Prop()
    ], LeaveCaseManageResolve.prototype, "selectionList", void 0);
    __decorate([
        Prop()
    ], LeaveCaseManageResolve.prototype, "leaveModel", void 0);
    LeaveCaseManageResolve = __decorate([
        Component({
            components: {
                DataBox: DataBox,
            },
        })
    ], LeaveCaseManageResolve);
    return LeaveCaseManageResolve;
}(Vue));
export default LeaveCaseManageResolve;
