var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit, Model } from 'vue-property-decorator';
var NumberRange = /** @class */ (function (_super) {
    __extends(NumberRange, _super);
    function NumberRange() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NumberRange.prototype.validate = function (rule, value, callback) {
        if (this.numberRange.min > this.numberRange.max) {
            callback(new Error('输入数字范围有误'));
        }
    };
    NumberRange.prototype.updateNumberRange = function (value) {
        this.numberRange.min = value.min;
        this.numberRange.max = value.max;
    };
    Object.defineProperty(NumberRange.prototype, "minValue", {
        get: function () {
            return this.numberRange.min;
        },
        set: function (value) {
            this.updateNumberRange({
                min: value || '',
                max: this.numberRange.max,
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NumberRange.prototype, "maxValue", {
        get: function () {
            return this.numberRange.max;
        },
        set: function (value) {
            this.updateNumberRange({
                min: this.numberRange.min,
                max: value || '',
            });
        },
        enumerable: false,
        configurable: true
    });
    NumberRange.prototype.parseInt = function (value) {
        if (this.isInteger) {
            return Math.round(Number.parseFloat(value));
        }
        return value;
    };
    __decorate([
        Prop({ default: '最小值' })
    ], NumberRange.prototype, "minPlaceholder", void 0);
    __decorate([
        Prop({ default: '最大值' })
    ], NumberRange.prototype, "maxPlaceholder", void 0);
    __decorate([
        Prop({
            type: Boolean,
            default: false,
        })
    ], NumberRange.prototype, "isInteger", void 0);
    __decorate([
        Model('numberRangeChange')
    ], NumberRange.prototype, "numberRange", void 0);
    __decorate([
        Emit('numberRangeChange')
    ], NumberRange.prototype, "updateNumberRange", null);
    NumberRange = __decorate([
        Component({
            components: {},
        })
    ], NumberRange);
    return NumberRange;
}(Vue));
export default NumberRange;
